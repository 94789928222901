import NavBar from "./components/navBar/navBar";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/index";
import AddListingPage from "./pages/listings/addListing";
import Listing from "./pages/listings/listing";
import Payment from "./pages/payment";
import Offers from "./pages/offers";
import MyOffers from "./pages/my-offers";
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";
import Profile from "./pages/profile";
import Messages from "./pages/messages";
import About from "./pages/content/About";
import Faq from "./pages/content/FAQ";
import Privacy from "./pages/content/Privacy";
import Terms from "./pages/content/Terms";
import Pricing from "./pages/content/Pricing";

function App() {
  const loggedUser = useSelector((state) => state.user);

  return (
    <div className="App">
      <div className="container lg mx-auto">
        <NavBar USER={loggedUser} />
        <Routes>
          <Route index element={<Home USER={loggedUser} />} />
          <Route path="/add-listing" element={<AddListingPage  />} />
          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/my-offers" element={<MyOffers />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/recover" element={<Home />} />

          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </div>
      <CookieConsent buttonText="I Agree" enableDeclineButton={true}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <Footer />
    </div>
  );
}

export default App;
