const Input = ({
  type,
  name,
  required,
  label,
  isError,
  errorMessage,
  handleChange,
  checked,
  placeholder,
  value,
  width
}) => {
  return (
    <div className={`inp-wrap w-full px-3 mb-6 md:mb-5 ${width ? width : 'md:w-1/4'}`}>
      <label
        className="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
        for={name}
      >
        {label}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type={type}
        id={name}
        name={name}
        required={required}
        value={value}
        placeholder={label}
        onChange={handleChange}
      />
      {errorMessage && <p className="input-error">{errorMessage}</p>}
    </div>
  );
};

export default Input;
