const ModalSubmitButton = ({ buttonText }) => {
  return (
    <div className="flex flex-col">
      <button
        className="w-100 p-3 bg-main-green text-white font-bold rounded-md text-base"
        type="submit"
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ModalSubmitButton;
