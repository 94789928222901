import React from "react";

const ModalContainer = (props) => {
  return (
    <div style={{'zIndex': "22222222"}} className="fixed top-0 w-screen h-screen bg-main-dark bg-local flex justify-center align-middle">
      <div
        className={`container w-fit h-fit px-8 py-6 bg-white rounded-md ${
          props.topMargin ? props.topMargin : "mt-24"
        }`}

        style={props.style}
      >
        <div className="relative flex flex-col gap-8 w-custom-modal">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
