const Terms = () => {
  return (
    <>
      {" "}
      <h1 className="content-page-title">Terms and Conditions</h1>
      <div className="content-page-content">
        <br></br>
        <br></br>
        <h2>1. Definitions</h2>
        <br></br>
        <p>
          1.1 Truck7 - Truck7 OÜ (registry code 16770094, address Kanarbiku 2,
          Soinaste küla, Kambja vald, Tartumaa, Republic of Estonia).<br></br>
          1.2 Database - The Truck7 database at www.truck7.eu, which contains
          listings of truck and trailer sales and rental offers.<br></br>
          1.3 User – Private or Legal person who has accepted the Terms and
          Conditions, has registered itself in the Database and created the User
          Account.<br></br>
          1.4 User Data - all data relating to the User entered into the
          Database by the User, including data entered in the Registration Form,
          data entered in the Database as an offer or an inquiry and other data
          on the User processed by Truck7.<br></br>
          1.5 Terms and Conditions - the current terms and conditions of use of
          the Truck7 Database for registered users. 1.6 Data Recipient - a legal
          entity that uses the Database, including for searching for and
          selecting of listings for itself or for its clients, or other services
          provided by Truck7 through the Portal, and to whom Truck7 provides or
          allows access to the User Data of the User in accordance with the
          Terms and Conditions for this purpose.<br></br>
          1.7 Parties - Truck7 and User.<br></br>
          1.8 Services - All Truck7 services related with listings of truck and
          trailer sales and rental offers.<br></br>
          1.9 Access data - the username and password chosen by the User upon
          registration as a User or later changed by the User in the Database.
          <br></br>
          1.10 Portal - The environment created by Truck7 for providing services
          related with listings of truck and trailer sales and rental offers at
          the domain Truck7.eu.<br></br>
        </p>
        <br></br>
        <h2>2 USER RIGHTS AND OBLIGATIONS</h2>
        <br></br>
        <p>
          2.1 After completing the registration form and accepting the Terms and
          Conditions, the User shall have full access for the use of the
          Services. <br></br>
          2.2 The User is required to enter only the correct and accurate User
          Data into the Database. <br></br>
          2.3 All rights to the Database belong to Truck7. The User does not
          acquire any rights to the Database or its parts when using the
          Database and Services. The User may use the information contained in
          the Database to make contact in good faith through the contact details
          provided in the sales and rental offers.. The User has no right to
          make other extracts or copies of the Database or parts thereof, or
          otherwise use the Database, or to damage or interfere with the
          operation of the Database. The User shall not acquire a copyright to
          the software. required to maintain the Database in any way (including
          for the purpose of using the Service). <br></br>
          2.6 The User has the right to supplement, correct and replace the User
          Data entered into the Database and decide on their availability to the
          recipients of Data (the right to change the status of the User Data,
          i.e., to make them active, passive or block them). <br></br>
          In order to delete a User Account, the User must send a request to
          this effect via e-mail to info@truck7.eu. Truck7 will delete the user
          account within 3 working days of receiving the respective request from
          the User. <br></br>
        </p>
        <br></br>
        <h2>3 RIGHTS AND OBLIGATIONS OF TRUCK7</h2>
        <br></br>
        <p>
          3.1 By agreeing to the Terms and Conditions by the User, the User
          gives consent to Truck7 at www.truck7.eu for the use of cookies for
          the purpose of personalizing the Service and contributing to providing
          the User with a better Service.<br></br>
          3.2 Truck7 allows the User to use the Services as specified in the
          Terms and Conditions and the Portal as of the User's registration on
          the Portal.<br></br>
          3.3 The User shall process the User Data on the basis of the Terms and
          Conditions as well as the contract concluded between the User and
          Truck7 to fulfill them and to the extent provided for therein.
          Processing of certain User Data, in accordance with the Service
          provided, also takes place for the purpose of fulfilling obligations
          under the Truck7, for example to the extent provided by the Accounting
          Act.<br></br>
          3.4 Truck7 points out that submission of User Data is an obligation
          arising from the Terms and Conditions, and failure to submit them or
          requesting termination or limitation of their processing may render
          Truck7 unable to provide the Services to the User in accordance with
          the Terms and Conditions. In the event that the User submits such a
          claim in relation to the processing of its User Data, Truck7 shall
          have the right to terminate the provision of the Services to the User
          and to delete the User Account together with all related data. In this
          case, the User may also request the deletion of its user account and
          all related information from the Truck7.<br></br>
          3.5 Truck7 processes (including collects, stores, saves, organizes,
          distributes, connects, uses, deletes, makes available to recipients of
          the Data, etc.) the User Data submitted by the User upon registration
          on the Portal and entered into the Database for the purpose of
          providing the Services to the User, as well as when it is required for
          performance of the statutory obligations of Truck7 or to protect the
          legitimate interests of Truck7.<br></br>
          3.6 Truck7 will not transmit User Data to third parties except:
          <br></br>
          1.3.6.1 to the recipients of the data in order to execute the
          contracts entered into with them or to ensure their performance;
          <br></br>
          2.3.6.2 to third party service providers used by Truck7 to provide
          services and ensure providing Services to the User.<br></br>
          3.7 The persons referred to in section 3.6 are authorized processors
          of User Data.<br></br>
          3.8 If Truck7 has reason to believe that User Data of the User entered
          into the Database is incorrect or erroneous, Truck7 shall have the
          right to delete the entered data from the Database.<br></br>
          3.9 Truck7 is not an authorized representative or agent of the Data
          provider, the data entry clerk of the offers/advertisements/inquiries,
          therefore Truck7 shall not be liable for any damage that may be caused
          to the User through sales and rental agreements established between
          Users of Truck7. The User enters into the pre- contractual
          negotiations with third parties associated with the Portal and
          concludes agreements/contracts independently with Truck7 and Truck7 is
          not responsible for the fulfillment of the promises, agreements or
          terms and conditions of the contract provided by the recipient of the
          Data/Third Parties associated with the Portal.<br></br>
          3.10 Truck7 does not guarantee to the User finding of a
          client/customer/seller/buyer through the Portal. 3.11 Truck7 shall not
          be liable for the unauthorized use of User Data of the User made
          available to the recipient of the Data, as well as for the
          confidentiality of such data, if confidentiality must be ensured by
          the recipient of the Data. 3.12 Truck7 has the right to notify the
          User of the services, capabilities, and news of itself and its
          business partners at the e-mail address entered upon registration of
          the Portal.<br></br>
          3.13 Truck7 has the right to notify the User by e-mail about new
          listings of truck and trailer sales and rental offers.<br></br>
          3.14 Truck7 has the right to place the Database, including the User
          Data contained therein, on a server located abroad. In this case,
          Truck7 remains the data controller.<br></br>
          3.15 Truck7 maintains User Data throughout the period of provision of
          the Services and until User Account of the User has not been deleted
          in accordance with Section 2.6.<br></br>
          3.16 In addition to the provisions of paragraph 3.15, Truck7 reserves
          the right to retain User Data for a period of time after the closure
          of the User Account, which is justified for the purpose of protecting
          the rights arising from the Terms and Conditions of Truck7 or law or
          other directly applicable law, in particular during expiry period for
          any claims.<br></br>
          3.17 Truck7 has the right to change the structure of the Database
          procedures and the menu without warning the User.<br></br>
          3.18 Truck7 has the right to unilaterally change the Terms and
          Conditions by notifying of changes and making new terms and conditions
          available on www.truck7.eu at least 5 days before the changes take
          effect. The changes will not apply retroactively to the Services
          ordered by the User prior to their entry into force.<br></br>
        </p>
        <br></br>
        <h2>4 LIABILITY OF THE CONTRACTING PARTIES</h2>
        <br></br>
        <p>
          4.1 Truck7 shall have the right to terminate unilaterally without a
          notice provision of the Services to the User without returning the to
          the User the fee paid for the Services, if the User has violated the
          Terms and Conditions and has not eliminated the violation within 3
          working days from the sending of the respective warning by Truck7 via
          e-mail.<br></br>
          4.2 The User shall have the right, at any time, to waive the further
          use of the Services by giving notice to Truck7 by e-mail. In such a
          case, the User shall not be entitled to demand reimbursement of the
          fee paid for the ordered Services.<br></br>
          4.3 The User shall be liable for any damage caused to Truck7 by a
          violation of the Terms and Conditions.<br></br>
          4.4 Truck7 shall not be liable for the accuracy or content of the
          information entered in the Database or for any damage arising to the
          User from the use of the information contained in the Database.
          <br></br>
        </p>
        <br></br>
        <h2>5 REFUNDS AND CANCELLATIONS</h2>
        <br></br>
        <p>
          5.1 Truck7 does not offer any refunds.<br></br>
          5.2 You can cancel your listing of truck and trailer sales and rental
          offers any time under my account "My listings" area.. No additional
          fees will be charged by cancelation. Your listing will be inactive
          immediately.
        </p>
        <br></br>
        <h2>6 FINAL PROVISIONS</h2>
        <br></br>
        <p>
          6.1 If the Terms and Conditions provide for notification of Truck7 by
          e-mail, the User shall send such notifications to info@truck7.eu.{" "}
          <br></br>
          6.2 If the Terms and Conditions provide for notification of Truck7 by
          telephone, the User shall transmit such notifications to the number
          +372 555 14 514 <br></br>
        </p>
        <br></br> <br></br>
        <br></br>
        <p>
          The Parties shall endeavour to settle all disputes and disagreements
          arising in the course of their duties by negotiation. If the agreement
          is not reached, the dispute shall be settled in the Tartu Courthouse
          of Tartu County Court in accordance with the legislation of the
          Republic of Estonia.
        </p>
      </div>
    </>
  );
};

export default Terms;
