import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { useEffect, useState } from "react";
import Sortable from "sortablejs";

const ImageUpload = ({ return_image_urls, initialImages }) => {
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [originalFiles, setOriginalfiles] = useState([]);
  const [indexes, setIndexes] = useState();

  useEffect(() => {
    if (initialImages.length > 0) {
      const img = [];
      for (const [i, imgOb] of initialImages) {
        img.push({
          id: Number(i),
          imageUrl: imgOb,
          file: "a.png",
        });
      }
      setFiles(img);
      setOriginalfiles(img);
    }
  }, [initialImages]);

  const updateFiles = (incommingFiles, deleteFunctionFiles) => {
    if (originalFiles && originalFiles.length > 0) {
      let lastId = originalFiles[originalFiles.length - 1].id;
      for (const inc of incommingFiles) {
        if (inc.id === lastId) {
          inc.id = lastId + 1;
          lastId++;
        }
      }
    }
    if (deleteFunctionFiles) {
      setFiles([...deleteFunctionFiles]);
      return_image_urls(uploadedFiles, files);
    } else {
      setFiles(originalFiles.concat(incommingFiles));
    }
    if (files.length === 0) {
      setUploadedFiles([]);
      return_image_urls([]);
    }
  };

  useEffect(() => {
    var el = document.getElementById("items");
    var sortable = new Sortable(el, {
      onUpdate: function (/**Event*/ evt) {
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;
        setIndexes({
          oldIndex,
          newIndex,
        });
        //
      },
    });
  }, []);

  const removeDuplicates = (array, property) => {
    return array.reduce((uniqueArray, currentItem) => {
      // Check if there's already an item with the same property value
      const existingItemIndex = uniqueArray.findIndex(
        (item) => item[property] === currentItem[property]
      );

      // If not found, add the current item to the uniqueArray
      if (existingItemIndex === -1) {
        uniqueArray.push(currentItem);
      } else {
        // If found, replace the existing item with the current item
        uniqueArray[existingItemIndex] = currentItem;
      }

      return uniqueArray;
    }, []);
  };

  useEffect(() => {
    if (indexes?.oldIndex !== undefined && indexes?.newIndex !== undefined) {
      const array = files;
      const indexToMove = indexes.oldIndex;
      const newIndex = indexes.newIndex;

      const newArray = [
        ...array.slice(0, newIndex),
        array[indexToMove],
        ...array.slice(newIndex, indexToMove),
        ...array.slice(indexToMove + 1),
      ];
      const fixed = removeDuplicates(newArray, "name");
      setFiles(fixed);
    }
  }, [indexes]);

  const handleFinish = (response) => {
    if (response.length > 0) {
      for (const file of response) {
        if (file.serverResponse && file.serverResponse.payload) {
          const previousFiles = uploadedFiles;
          previousFiles.push(file.serverResponse.payload);
          setUploadedFiles(previousFiles);
          return_image_urls(previousFiles, files);
        }
      }
    }
  };

  return (
    <>
      <Dropzone
        onChange={updateFiles}
        clickable
        uploadConfig={{
          url: `${process.env.REACT_APP_API_URL}/listing/upload`,
          autoUpload: true,
        }}
        displayExistingFile
        onUploadFinish={handleFinish}
        actionButtons={{
          position: "after",
          abortButton: {},

          uploadButton: {},
        }}
        accept="image/*"
        view="grid"
        value={files}
      ></Dropzone>
      <div
        id="items"
        style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}
      >
        {files?.map((file, i) => (
          <>
            {console.log("xee")}
            <FileMosaic
              key={i}
              {...file}
              preview
              onDelete={(index) => {
                const f = files.findIndex((el) => el.id === index);
                //IF IS NEW FILE
                if (files[f].xhr) {
                  const res = JSON.parse(files[f].xhr.response);
                  const payload = res.payload;
                  const ind = uploadedFiles.findIndex((el) => el === payload);
                  const spliced = uploadedFiles.splice(ind,1)
                  setUploadedFiles({...spliced})
                }
                files.splice(f, 1);
                updateFiles([], files);
              }}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default ImageUpload;
