import categories from "../data/categories.json";
import brands from "../data/brands.json";
import cabins from "../data/cabins.json";
import colors from "../data/colors.json";
import euro_cat from "../data/euro_cat.json";
import wheel_formula from "../data/wheel_formula.json";
import countries from "../data/countries.json";
import axles from "../data/axles.json";
import suspension from "../data/suspension.json";
import fuels from "../data/fuels.json";
import transmission from "../data/transmission.json";
import axle_rations from "../data/axle_ratios.json";
import alloy_wheels from "../data/alloy_wheels.json";
import air_conditioning from "../data/air_conditioning.json";
import cruise_control from "../data/cruise_control.json";
import hydraulic_installation from "../data/hydraulic_installation.json";
import driving_cab from "../data/driving_cab.json";
import sliding_door from "../data/sliding_door.json";

const years = () => {
  const firstRegistration = []
  const range = (start, stop, step) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step
        );
      const currentYear = new Date().getFullYear();

      const reg = range(currentYear, currentYear - 25, -1);

      for (const yr of reg) {

       firstRegistration.push({
          value: yr,
          label: yr,
        });
      }
      return firstRegistration
}


const generalInputs = [
  {
    key: "category",
    label: "Type",
    type: "select",
    required: true,
    options: categories,
    section: "general",
  },
  {
    key: "vin",
    type: "text",
    required: true,
    label: "VIN Code",
    section: "general",
  },

  {
    key: "brand",
    label: "Brand",
    type: "select",
    options: brands,
    required: true,
    section: "general",
  },
  {
    key: "model",
    label: "Model",
    type: "text",
    required: true,
    section: "general",
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
  },
  {
    key: "cabin_name",
    label: "Cabin",
    type: "select",
    options: cabins,
    required: false,
    section: "general",
    disabled: ["trailer", "semi-trailer"],
  },
  {
    key: "first_registration",
    label: "First Registration",
    type: "month",
    max:  new Date().toISOString().split("T")[0],
    required: true,
    section: "general",
  },
  {
    key: "first_registration_filter",
    label: "First Registration",
    type: "select",
    options: years(),
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
  },


  {
    key: "country",
    label: "Country",
    type: "select",
    options: countries,
    required: true,
    section: "general",
  },
  {
    key: "city",
    label: "City",
    type: "text",
    required: false,
    section: "general",
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
  },
  {
    key: "color",
    label: "Color",
    type: "select",
    options: colors,
    required: false,
    section: "general",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },
  {
    key: "price",
    label: "Price (€)",
    type: "number",
    min: 0,
    step: 0.01,
    required: false,
    section: "general",
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
    isRange: true,
  },
  {
    key: "description.en.general",
    label: "Description",
    type: "textarea",
    required: false,
    section: "general",
  },

  {
    key: "motor_hours",
    label: "Motor Hours",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
  },

  {
    key: "mileage",
    label: "Milage (km)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
    isRange: true,
  },
  {
    key: "horse_power",
    label: "Power (hp)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
    isRange: true,
  },
  {
    key: "kw_power",
    label: "Power (kW)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
    isRange: true,
  },

  {
    key: "euro_category",
    label: "Euro Category",
    type: "select",
    options: euro_cat,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },

  {
    key: "fuel_type",
    label: "Fuel Type",
    type: "select",
    options: fuels,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },

  {
    key: "engine_volume",
    label: "Engine Volume",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
  },

  {
    key: "cubic_capacity",
    label: "Cubic Capacity (cc)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
  },

  {
    key: "number_of_gears",
    label: "Number of Gears",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
  },
  {
    key: "gearbox",
    label: "Gearbox",
    type: "select",
    options: transmission,
    required: false,
    section: "engine",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },

  {
    key: "height",
    label: "Height (cm)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "dimensions",
  },

  {
    key: "length",
    label: "Lenght (cm)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "dimensions",
  },
  {
    key: "width",
    label: "Width (cm)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "dimensions",
  },

  {
    key: "carrying_capacity",
    label: "Carrying Capacity (kg)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "dimensions",
  },

  {
    key: "maximum_load",
    label: "Maximum load (kg)",
    type: "number",
    min: 0,
    step: 1,
    required: false,
    section: "dimensions",
  },

  {
    key: "axle_configuration",
    label: "Axle Configuration",
    type: "select",
    options: wheel_formula,
    required: false,
    section: "axles",
    disabled: ["trailer", "semi-trailer", "vans-and-trucks-over-7.5"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },

  {
    key: "total_axles",
    label: "Total Axles",
    type: "select",
    options: axles,
    required: false,
    section: "axles",
    disabled: ["vans-and-trucks-over-7.5"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "semi-trailer",
      "trailer",
    ],
  },

  {
    key: "front_axle.tyre_size",
    label: "Tire Size (···/·· R··.·)",
    type: "text",
    required: false,
    section: "front-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "front_axle.ratio_left",
    label: "Ratio Left",
    type: "select",
    options: axle_rations,
    required: false,
    section: "front-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "front_axle.suspension",
    label: "Suspension",
    type: "select",
    options: suspension,
    required: false,
    section: "front-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "front_axle.ratio_right",
    label: "Ratio Right",
    type: "select",
    options: axle_rations,

    required: false,
    section: "front-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "front_axle.max_axle_load",
    label: "Max Axle Load (kg)",
    type: "number",
    min: 0,
    step: 0.01,
    required: false,
    section: "front-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "front_axle.type_of_brakes",
    label: "Type of brakes",
    type: "text",
    required: false,
    section: "front-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },

  {
    key: "rear_axle.tyre_size",
    label: "Tire Size (···/·· R··.·)",
    type: "text",
    required: false,
    section: "rear-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_axle.ratio_left",
    label: "Ratio Left",
    type: "select",
    options: axle_rations,

    required: false,
    section: "rear-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_axle.suspension",
    label: "Suspension",
    type: "select",
    options: suspension,
    required: false,
    section: "rear-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_axle.ratio_right",
    label: "Ratio Right",
    type: "select",
    options: axle_rations,
    required: false,
    section: "rear-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_axle.max_axle_load",
    label: "Max Axle Load (kg)",
    type: "number",
    min: 0,
    step: 0.01,
    required: false,
    section: "rear-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_axle.type_of_brakes",
    label: "Type of brakes",
    type: "text",
    required: false,
    section: "rear-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },

  {
    key: "rear_mid_axle.tyre_size",
    label: "Tire Size (···/·· R··.·)",
    type: "text",
    required: false,
    section: "rear-mid-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_mid_axle.ratio_left",
    label: "Ratio Left",
    type: "select",
    options: axle_rations,
    required: false,
    section: "rear-mid-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_mid_axle.suspension",
    label: "Suspension",
    type: "select",
    options: suspension,
    required: false,
    section: "rear-mid-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_mid_axle.ratio_right",
    label: "Ratio Right",
    type: "select",
    options: axle_rations,
    required: false,
    section: "rear-mid-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_mid_axle.max_axle_load",
    label: "Max Axle Load (kg)",
    type: "number",
    min: 0,
    step: 0.01,
    required: false,
    section: "rear-mid-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "rear_mid_axle.type_of_brakes",
    label: "Type of brakes",
    type: "text",
    required: false,
    section: "rear-mid-axle",
    disabled: ["vans-and-trucks-over-7.5"],
  },

  {
    key: "accessories_set.alloy_wheels",
    type: "select",
    label: "Alloy Wheels",
    options: alloy_wheels,
    section: "accessories",
    disabled: ["trailer", "semi-trailer", "vans-and-trucks-over-7.5"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.air_conditioning",
    type: "select",
    label: "Air Conditioning",
    options: air_conditioning,
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },
  {
    key: "accessories_set.cruise_control",
    type: "select",
    label: "Cruise Control",
    options: cruise_control,
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },
  {
    key: "accessories_set.hydraulic_installation",
    type: "select",
    label: "Hydraulic Installation",
    options: hydraulic_installation,
    section: "accessories",
    disabled: ["trailer", "semi-trailer", "vans-and-trucks-over-7.5"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.driving_cab",
    type: "select",
    label: "Driving Cab",
    options: driving_cab,
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },

  {
    key: "accessories_set.sliding_door",
    type: "select",
    label: "Sliding Door",
    options: sliding_door,
    section: "accessories",
    disabled: ["trailer", "semi-trailer", "tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.no_of_seats",
    type: "number",
    min: 0,
    step: 1,
    label: "Number of seats",
    section: "accessories",
    disabled: ["trailer", "semi-trailer", "tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.abs",
    type: "checkbox",
    label: "ABS",
    section: "accessories",
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
  },
  {
    key: "accessories_set.ebs",
    type: "checkbox",
    label: "EBS",
    section: "accessories",
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
  },
  {
    key: "accessories_set.esp",
    type: "checkbox",
    label: "ESP",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
      "semi-trailer",
      "trailer",
      "reefer-semitrailers",
      "tilt-semitrailers",
    ],
  },

  {
    key: "accessories_set.auxiliary_heating",
    type: "checkbox",
    label: "Auxiliary Heating",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.navigation_system",
    type: "checkbox",
    label: "Navigation System",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },

  {
    key: "accessories_set.retarder_intarder",
    type: "checkbox",
    label: "Retarder/Intarder",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.secondary_air_con",
    type: "checkbox",
    label: "Secondary Air Conditioning",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: ["tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.four_wheel_drive",
    type: "checkbox",
    label: "Four Wheel drive",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },
  {
    key: "accessories_set.particulate_filter",
    type: "checkbox",
    label: "Pariticulate Filter",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },
  {
    key: "accessories_set.power_assisted_steering",
    type: "checkbox",
    label: "Power Assisted Steering",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
    filterEnabled: [
      "tractor-unit",
      "trucks-over-7.5",
      "vans-and-trucks-over-7.5",
    ],
  },
  {
    key: "accessories_set.crane",
    type: "checkbox",
    label: "Crane",
    section: "accessories",
    disabled: ["trailer", "semi-trailer", "tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.tail_lift",
    type: "checkbox",
    label: "Tail Lift",
    section: "accessories",
    disabled: ["trailer", "semi-trailer", "tractor-unit", "trucks-over-7.5"],
  },
  {
    key: "accessories_set.parking_sensors_front",
    type: "checkbox",
    label: "Parking Sensors Front",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
  },
  {
    key: "accessories_set.parking_sensors_rear",
    type: "checkbox",
    label: "Parking Sensors Rear",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
  },
  {
    key: "accessories_set.parking_camera",
    type: "checkbox",
    label: "Parking Camera",
    section: "accessories",
    disabled: ["trailer", "semi-trailer"],
  },
  {
    key: "accessories_set.air_suspension",
    type: "checkbox",
    label: "Air Suspension",
    section: "accessories",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "accessories_set.disk_brakes",
    type: "checkbox",
    label: "Disk Brakes",
    section: "accessories",
    disabled: ["vans-and-trucks-over-7.5"],
  },
  {
    key: "author_name",
    label: "Name",
    type: "text",
    required: true,
    section: "contact",
  },
  {
    key: "author_email",
    label: "Email",
    type: "text",
    required: true,
    section: "contact",
  },
  {
    key: "author_company",
    label: "Company",
    type: "text",
    required: false,
    section: "contact",
  },
  {
    key: "author_phone",
    label: "Phone",
    type: "text",
    required: false,
    section: "contact",
  },
];

const listing_config = generalInputs;

export default listing_config;
