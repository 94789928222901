const Input = ({
  key,
  type,
  min,
  max,
  step,
  name,
  label,
  required,
  handleChange,
  listingValue,
  errors,
  isRange,
  isFilter,
  params,
}) => {
  let mapping = listingValue[name];
  const nameSpit = name.split(".");
  if (nameSpit.lenght > 1) {
    mapping = mapping[nameSpit[0]][nameSpit[1]];
  }
  if (nameSpit.lenght > 2) {
    mapping = mapping[nameSpit[0]][nameSpit[1]][nameSpit[2]];
  }

  let isError = false;
  let errorMessage = "";

  if (errors && errors.length > 0) {
    isError = errors.filter((el) => {
      if (el.path === name) {
        errorMessage = el.msg;
        return true;
      }
    });
  }
  return (
    <>
      {type === "checkbox" ? (
        <div
          className={
            isFilter
              ? `inp-wrap isFilter w-full md:w-1/6 px-3 mb-6 md:mb-5`
              : `inp-wrap w-full md:w-1/6 px-3 mb-6 md:mb-5 checkbox`
          }
        >
          <input
            className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type={type}
            id={name}
            name={name}
            required={required}
            checked={mapping}
            placeholder={label}
            onChange={handleChange}
            //  value={mapping}
            value={mapping ? mapping : params && params[name] === 'on' && true}
          />
          <label
            className="  tracking-wide text-gray-700 text-xs font-bold mb-2"
            for={name}
          >
            {label}
          </label>
          {isError && <p className="input-error">{errorMessage}</p>}
        </div>
      ) : type === "textarea" ? (
        <div className="inp-wrap  w-full md:w-1/2 px-3 mb-6 md:mb-5">
          <label
            className="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
            for={name}
          >
            {label}
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type={type}
            id={name}
            name={name}
            required={required}
            //  value={mapping}
            value={mapping ? mapping : params && params[name]}
            placeholder={label}
            onChange={handleChange}
          />
          {isError && <p className="input-error">{errorMessage}</p>}
        </div>
      ) : (
        <div className="inp-wrap w-full md:w-1/2 px-3 mb-6 md:mb-5">
          {isRange && isFilter ? (
            <>
              <div className="flex w-full flex-1  flex-1 items-end 	">
                <div className=" w-full md:w-5/12  px-3   flex flex-col">
                  <label
                    className="tracking-wide text-gray-700 text-xs font-bold mb-2 basis-1/2 block"
                    for={name + "_from"}
                  >
                    {label}
                  </label>
                  <input
                    className="w-full shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type={type}
                    id={name + "_from"}
                    name={name + "_from"}
                    required={required}
                    // value={mapping}
                    value={mapping ? mapping : params && params[name]}
                    placeholder={label}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    step={step}
                  />
                </div>
                <div className=" w-full md:w-1/12 flex flex-col text-center">
                  To
                </div>
                <div className=" w-full md:w-5/12  px-3   flex flex-col">
                  <label
                    className="tracking-wide text-gray-700 text-xs font-bold mb-2 basis-1/2 block"
                    for={name + "_to"}
                  ></label>
                  <input
                    style={{ height: "38px" }}
                    className="w-full shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type={type}
                    id={name + "_to"}
                    name={name + "_to"}
                    required={required}
                    // value={mapping}
                    value={mapping ? mapping : params && params[name]}
                    placeholder={label}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    step={step}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <label
                className="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
                for={name}
              >
                {label}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type={type}
                id={name}
                name={name}
                required={required}
                // value={mapping}
                value={mapping ? mapping : params && params[name]}
                placeholder={label}
                onChange={handleChange}
                min={min}
                max={max}
                step={step}
              />
              {isError && <p className="input-error">{errorMessage}</p>}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Input;
