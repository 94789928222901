import Select from "react-select";

import { useEffect, useState } from "react";

const FilterElement = (props) => {
  const [filterOptions, setFilterOptions] = useState();
  const [loadedValue, setLoadedValue] = useState();


  useEffect(() => {
    if (props.values) {
      setFilterOptions(props.values);
    }
  }, [props.values]);

  useEffect(() => {
    if (props.params && props.params.lenght > 0) {
   
    }
  }, [props.params]);

  const handleChange = (selectedOption) => {
    props.setFilter({ name: props.name, value: selectedOption?.value });
  };

  return (
    <div
      className={
        props.type === 2 ? `filter-input-wrapper-2` : `filter-input-wrapper`
      }
    >
      {filterOptions && (
        <>
          {props?.extraLabel && <label>{props.extraLabel}</label>}

          {props?.type ? (
            <input
              style={{
                padding: " 2px 8px",
                background: "hsl(0, 0%, 100%)",
                borderColor: "hsl(0, 0%, 80%)",
                borderRadius: "4px",
                borderStyle: "solid",
                borderWidth: "1px",
                height: "38px",
                margin: "8px",
                color: "hsl(0, 0%, 50%)",
              }}
              //value={props?.params && props.params[props?.name]}
              name={props?.name}
              placeholder={props?.label}
              type={props.type}
              onChange={handleChange}
            ></input>
          ) : (
            <>
              <Select
                isClearable
                cacheOptions
                defaultOptions
                placeholder={props.label}
                name={props?.name}
                //value={{ label: props.name, value: props.params[props.name] }}
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "90%",
                    color: "#49475c",
                    padding: "8px",
                  }),
                }}
                //  value={filterOptions}
                options={filterOptions}
                onChange={handleChange}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FilterElement;

