const Select = ({
  key,
  label,
  name,
  options,
  required,
  handleChange,
  listingValue,
  errors,
}) => {
  const active = listingValue.category;

  let mapping = listingValue[name];
  const nameSpit = name.split(".");
  if (nameSpit.lenght > 1) {
    mapping = mapping[nameSpit[0]][nameSpit[1]];
  }
  if (nameSpit.lenght > 2) {
    mapping = mapping[nameSpit[0]][nameSpit[1]][nameSpit[2]];
  }
  let isError = false;
  let errorMessage = ''

  if (errors && errors.length > 0) {
    isError = errors.filter((el) => {
      if (el.path === name) {
        errorMessage = el.msg
        return true
      }
    });
  }




  return (
    <div className="inp-wrap w-full md:w-1/2 px-3 mb-6 md:mb-5 cutsom-select">
      <label
        className="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
        for={name}
      >
        {label}
      </label>
      <select
        className="shadow appearance-none cutsom-select border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={name}
        name={name}
        onChange={handleChange}
        value={listingValue[nameSpit]}
        required={required}
      >
        {options.map((el) => {
          let visible = true;
          if (
            el.disabled &&
            el.disabled.length > 0 &&
            el.disabled.includes(active)
          ) {
            visible = false;
          }

          return (
            visible && (
              <option key={el.value} value={el.value}>
                {el.label}
              </option>
            )
          );
        })}
      </select>
      {isError && <p className="input-error">{errorMessage}</p>}
    </div>
  );
};

export default Select;
