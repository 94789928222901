import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import GeneralContainer from "../../../components/General/GeneralContainer";
import ImageUpload from "./ImageUpload";
import styles from "../styles/styles.module.css";
import listing_config from "../../../config/create_listing";

import FormSection from "./FormSection";
const AddListingForm = (props) => {
  const [searchParams] = useSearchParams();
  const [initialImages, setInitialImages] = useState([]);
  const [listingValue, setListingValue] = useState({
    vin: "",
    dropzone: [],
    category: "",
    brand: "",
    model: "",
    assembly_code: "",
    cabin_name: "",
    first_registration: "",
    motor_hours: "",
    mileage: "",
    color: "",
    horse_power: "",
    kw_power: "",
    euro_category: "",
    fuel_type: "",
    engine_volume: "",
    cubic_capacity: "",
    number_of_gears: "",
    height: "",
    length: "",
    width: "",
    carrying_capacity: "",
    maximum_load: "",
    accessories_set: {},
    axle_configuration: "",
    total_axles: "",
    suspension: "",
    front_axle: {
      tyre_size: "",
      ratio_left: "",
      suspension: "",
      ratio_right: "",
      max_axle_load: "",
      type_of_brakes: "",
    },
    rear_axle: {
      tyre_size: "",
      ratio_left: "",
      suspension: "",
      ratio_right: "",
      max_axle_load: "",
      type_of_brakes: "",
    },
    rear_mid_axle: {
      tyre_size: "",
      ratio_left: "",
      suspension: "",
      ratio_right: "",
      max_axle_load: "",
      type_of_brakes: "",
    },
    description: {},
    price: "",
    country: "",
    country_code: "",
    city: "",
    stock_code: "",
    url_list: {},
    damaged: "",
    pre_reserved: "",
    gearbox: "",
    author_name: "",
    author_email: "",
    author_company: "",
    author_phone: "",
    _creator: "",
  });

  const [errors, setErrors] = useState([]);

  
  console.log(listingValue, "lll");

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/listing/${id}`)
        .then((res) => {
          if (
            res.data._creator &&
            res.data._creator.toString() ===
              JSON.parse(props.user)._id.toString()
          ) {
            setListingValue(res.data);
            if (res.data.url_list) {
              setInitialImages(Object.entries(res.data.url_list));
            }
          }
        });
    }
  }, []);

  const navigate = useNavigate();
  const get_image_urls = (data, data2) => {
    let images = data;
    if (data2 && data2.length > 0) {
      const oldImages = data2.map(function (i) {
        return i.imageUrl;
      });
      images = images.concat(oldImages);
    }
    setListingValue({
      ...listingValue,
      ["url_list"]: Object.assign({}, images),
      ["dropzone"]: data2,
    });
  };
  useEffect(() => {
    if (props.user) {
      console.log('siin')
      const user = JSON.parse(props.user);

      setListingValue({
        ...listingValue,
        author_name: user.firstName + " " + user.lastName,
        author_email: user.email,
        author_company: user.company,
        author_phone: user.phone,
        _creator: user._id,
      });
    } else {
      navigate("/");
    }
  }, [props.user]);

  const handleChange = (e) => {
    let { name, value, checked, type } = e.target;

    if (errors && errors.length > 0) {
      setErrors(errors.filter((el) => el.path !== name));
    }
    if (type === "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    if (name.includes(".")) {
      const nameSplit = name.split(".");
      const newObject = listingValue[nameSplit[0]];
      newObject[nameSplit[1]] = value;

      setListingValue({ ...listingValue, [nameSplit[0]]: newObject });
    } else {
      setListingValue({ ...listingValue, [name]: value });
    }
    if (name === "country") {
      const code = e.target[e.target.selectedIndex].getAttribute("code");

      setListingValue({
        ...listingValue,
        [name]: value,
        ["country_code"]: code,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!listingValue._id) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/listing`,
          {
            ...listingValue,
          }
        );
        navigate(`/payment?id=${response.data.truckListing._id}`);
      } else {
        await axios.put(`${process.env.REACT_APP_API_URL}/listing`, {
          ...listingValue,
        });
        navigate(`/my-offers`);
      }
    } catch (err) {
      if (err.response.data.errors) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <GeneralContainer>
      <h1 className={styles.header}>Add your truck</h1>
      <div className={styles.uploadArea}>
        <h2 className={styles.subHeader}>Upload images</h2>
        <ImageUpload
          initialImages={initialImages}
          return_image_urls={get_image_urls}
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mt-10 block">
            <h2 className={styles.subHeader}>General information</h2>
          </div>

          <FormSection
            fields={listing_config.filter((el) => el.section === "general")}
            handleChange={handleChange}
            listingValue={listingValue}
            errors={errors}
          ></FormSection>
        </div>
        {listingValue.category !== "trailer" &&
          listingValue.category !== "semi-trailer" && (
            <div className="flex flex-wrap mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mt-10 block">
                <h2 className={styles.subHeader}>Engine</h2>
              </div>

              <FormSection
                fields={listing_config.filter((el) => el.section === "engine")}
                handleChange={handleChange}
                listingValue={listingValue}
                errors={errors}
              ></FormSection>
            </div>
          )}
        <div className="flex flex-wrap mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mt-10 block">
            <h2 className={styles.subHeader}>Dimensions</h2>
          </div>

          <FormSection
            fields={listing_config.filter((el) => el.section === "dimensions")}
            handleChange={handleChange}
            listingValue={listingValue}
            errors={errors}
          ></FormSection>
        </div>
        {listingValue.category !== "vans-and-trucks-over-7.5" && (
          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mt-10 block">
              <h2 className={styles.subHeader}>Axles</h2>
            </div>

            <FormSection
              fields={listing_config.filter((el) => el.section === "axles")}
              handleChange={handleChange}
              listingValue={listingValue}
              errors={errors}
            ></FormSection>
          </div>
        )}

        {listingValue.category !== "vans-and-trucks-over-7.5" && (
          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mt-10 block">
              <h2 className={styles.subHeader}>Front Axle</h2>
            </div>

            <FormSection
              fields={listing_config.filter(
                (el) => el.section === "front-axle"
              )}
              handleChange={handleChange}
              listingValue={listingValue}
              errors={errors}
            ></FormSection>
          </div>
        )}

        {listingValue.category !== "vans-and-trucks-over-7.5" && (
          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mt-10 block">
              <h2 className={styles.subHeader}>Rear Axle</h2>
            </div>

            <FormSection
              fields={listing_config.filter((el) => el.section === "rear-axle")}
              handleChange={handleChange}
              listingValue={listingValue}
              errors={errors}
            ></FormSection>
          </div>
        )}

        {listingValue.category !== "vans-and-trucks-over-7.5" && (
          <div className="flex flex-wrap mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mt-10 block">
              <h2 className={styles.subHeader}>Rear-Mid Axle</h2>
            </div>

            <FormSection
              fields={listing_config.filter(
                (el) => el.section === "rear-mid-axle"
              )}
              handleChange={handleChange}
              listingValue={listingValue}
            ></FormSection>
          </div>
        )}

        <div className="flex flex-wrap mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mt-10 block">
            <h2 className={styles.subHeader}>Accessories</h2>
          </div>

          <FormSection
            fields={listing_config.filter((el) => el.section === "accessories")}
            handleChange={handleChange}
            listingValue={listingValue}
          ></FormSection>
        </div>

        <div className="flex flex-wrap mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mt-10 block">
            <h2 className={styles.subHeader}>Contact Details</h2>
          </div>

          <FormSection
            fields={listing_config.filter((el) => el.section === "contact")}
            handleChange={handleChange}
            listingValue={listingValue}
            errors={errors}
          ></FormSection>
        </div>
        <button
          className={styles.submit}
          onClick={(e) => handleSubmit(e)}
          type="submit"
        >
          Submit
        </button>
      </form>
    </GeneralContainer>
  );
};

export default AddListingForm;
