import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddListingForm from "./components/addListingForm";

const AddListingPage = ({USER}) => {
  const creator = localStorage.getItem("user");

  useEffect(() => {
    if (!creator) {
      
      var event = new CustomEvent("login", {
        detail: "Please log in",
      });
     document.dispatchEvent(event);
  
    }
  }, []);

  return <div>{creator && <AddListingForm user={creator} />}</div>;
};

export default AddListingPage;
