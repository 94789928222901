import OfferCard from "./OfferCard";
import axios from "axios";
import { useState, useEffect } from "react";
import ClassTrucksLogo from "../../assets/class-trucks-small-logo.svg";
import useWindowDimensions from "../../helpers/WindowDimensions";

const PromotedContainer = (props) => {
  const [offers, setOffers] = useState([]);
  const [visibleOffers, setVisibleOffers] = useState([]);

  const [countryFiler, setCountryFilter] = useState("Lithuania");
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (offers.length > 0) {
      if (width > 1568) {
        setVisibleOffers(offers.slice(0, 8));
      }
      if (width < 1568) {
        setVisibleOffers(offers.slice(0, 8));
      }
      if (width < 1280) {
        setVisibleOffers(offers.slice(0, 4));
      }
      if (width < 996) {
        setVisibleOffers(offers.slice(0, 6));
      }
      if (width < 568) {
        setVisibleOffers(offers.slice(0, 8));
      }
    }
  }, [width, offers]);

  useEffect(() => {
    const a = process.env.REACT_APP_API_URL
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/listings?limit=4&is_class_trucks=true&external_id=true&country=${countryFiler}`
      )
      .then((res) => {
        setOffers(res.data.listings);
      })
      .catch((err) => console.log(err));
  }, [countryFiler]);

  return (
    <div className="promoted-container flex content-start justify-center">
      <div className="header">
        <h2 className="section-title">Promoted Offers</h2>
        <img src={ClassTrucksLogo} />
        <div className="promotion-filter">
          <button
            onClick={() => {
              setCountryFilter("Lithuania");
            }}
            value="Lithuania"
            className={`filter-element ${
              countryFiler === "Lithuania" && "active"
            }`}
          >
            Lithuania
          </button>
          <button
            onClick={() => {
              setCountryFilter("Poland");
            }}
            value="Poland"
            className={`filter-element ${
              countryFiler === "Poland" && "active"
            }`}
          >
            Poland
          </button>
          <button
            onClick={() => {
              setCountryFilter("Netherlands");
            }}
            value="Netherlands"
            className={`filter-element ${
              countryFiler === "Netherlands" && "active"
            }`}
          >
            Netherlands
          </button>
          <button
            onClick={() => {
              setCountryFilter("Spain");
            }}
            value="Spain"
            className={`filter-element ${countryFiler === "Spain" && "active"}`}
          >
            Spain
          </button>
        </div>
      </div>

      {visibleOffers.length > 0 &&
        visibleOffers.map((offer) => <OfferCard key={offer._id} offer={offer} />)}
    </div>
  );
};

export default PromotedContainer;
