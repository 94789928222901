import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const CategoryTab = (props) => {
  const navigate = useNavigate();



  return (
    <div
      onClick={() => {
        navigate(`/offers?category=${props.value}`);
      }}
      className={styles.filterTab}
    >
      <p style={{'textTransform': "capitalize"}}>{props.name.replaceAll('-', ' ')} <strong>({props.count})</strong></p>
      
    </div>
  );
};

export default CategoryTab;
