import Select from "./Select";
import Input from "./Input";

const FormSection = ({
  fields,
  handleChange,
  listingValue,
  errors,
  isFilter,
  params,
}) => {
  const active = listingValue.category;

  return (
    <>
      {fields.map((el) => {
        if (el.type === "select") {
          let visible = true;
          if (
            el.disabled &&
            el.disabled.length > 0 &&
            el.disabled.includes(active)
          ) {
            visible = false;
          }
          if (visible) {
            return (
              <Select
                errors={errors}
                key={el.key}
                name={el.key}
                listingValue={listingValue}
                options={el.options}
                label={el.label}
                required={el?.required ? el.required : false}
                handleChange={handleChange}
                params={params}
              ></Select>
            );
          }
        } else {
          let visible = true;
          if (
            el.disabled &&
            el.disabled.length > 0 &&
            el.disabled.includes(active)
          ) {
            visible = false;
          }
          if (visible) {
            return (
              <Input
                errors={errors}
                key={el.key}
                name={el.key}
                listingValue={listingValue}
                label={el.label}
                type={el.type}
                min={el.min}
                max={el.max}
                step={el.step}
                required={el?.required ? el.required : false}
                handleChange={handleChange}
                isRange={el.isRange}
                isFilter={isFilter}
                params={params}
              ></Input>
            );
          }
        }
      })}
    </>
  );
};

export default FormSection;
