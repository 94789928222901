import Input from "../components/Form/Input";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "../pages/listings/styles/styles.module.css";

import { ToastContainer, toast } from "react-toastify";

const Profile = () => {
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())

      .then((res) => {
        setProfile(res.user);
      });
  }, []);

  const handleSubmit = (e) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          profile,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.success("Profile Updated", {
          position: "bottom-left",
        });
      })
      .catch((err) => {
        toast.error("Update failed", {
          position: "bottom-left",
        });
      });
  };

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };
  return (
    <>
      <ToastContainer />

      <Input
        handleChange={handleChange}
        type="text"
        name="firstName"
        value={profile?.firstName}
        label="First Name"
      />
      <Input
        handleChange={handleChange}
        type="text"
        name="lastName"
        value={profile?.lastName}
        label="Last Name"
      />
      <Input
        handleChange={handleChange}
        type="text"
        name="company"
        value={profile?.company}
        label="Company"
      />
      <Input
        handleChange={handleChange}
        type="text"
        name="address"
        value={profile?.address}
        label="Company Address"
      />
      <Input
        handleChange={handleChange}
        type="text"
        name="vat"
        value={profile?.vat}
        label="VAT number"
      />
      <Input
        handleChange={handleChange}
        type="text"
        name="website"
        value={profile?.website}
        label="Website"
      />
      <Input
        handleChange={handleChange}
        type="text"
        name="phone"
        value={profile?.phone}
        label="Phone"
      />

      <button
        className={styles.submit}
        onClick={(e) => handleSubmit(e)}
        type="submit"
      >
        Save
      </button>
    </>
  );
};

export default Profile;
