import React, { useState } from "react";
import { createPortal } from "react-dom";
import RegisterModal from "./Modals/registerModal";



const RegisterPortal = () => {

  

  const [showModal, setShowModal] = useState(false);


  //LISTEN TO EVETN
  document.addEventListener("register", function (e) {
    setShowModal(true);
  });
  return (
    <div>
      <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => {
          console.log("open");
          setShowModal(true);
        }}
      >
        Join now
      </button>
      {showModal &&
        createPortal(
          <RegisterModal
            onClose={() => {
              setShowModal(false);
            }}
          />,
          document.body
        )}
    </div>
  );
};

export default RegisterPortal;
