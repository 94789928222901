import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/reducers/userReducer";
import { ToastContainer, toast } from "react-toastify";
import ModalContainer from "../..//General/ModalContainer";
import CloseButton from "../../../assets/icons/x-mark";
import ModalSubmitButton from "../../General/Buttons/ModalSubmitButton";
import LoginPortal from "../LoginPortal";

const RegisterModal = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
    passwordRepeat: "",
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
  });

  const {
    email,
    password,
    firstName,
    lastName,
    company,
    phone,
    passwordRepeat,
  } = inputValue;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (inputValue.password !== inputValue.passwordRepeat) {
        return handleError("Passwords do not match");
      }
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        ...inputValue,
      });

      const { success, message, user } = data;

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          localStorage.setItem('user', JSON.stringify(user))
          dispatch(setUser(user));
          window.location.reload();
          onClose();
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }

    setInputValue({
      ...inputValue,
      email: "",
      password: "",
      passwordRepeat: "",
      firstName: "",
      lastName: "",
      company: "",
      phone: "",
    });
  };

  return (
    <ModalContainer topMargin="mt-6"
      style={{maxHeight: '80%', overflow: 'scroll'}}
    >
      <CloseButton onClose={onClose} />
      <h2 className="text-2xl font-bold">Register</h2>
      <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-0">
          <label className="mr-4 text-sm" htmlFor="firstName">
            First name:
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="text"
            name="firstName"
            value={firstName}
            placeholder="Enter your first name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-4 text-sm" htmlFor="lastName">
            Last name:
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="text"
            name="lastName"
            value={lastName}
            placeholder="Enter your last name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-4 text-sm" htmlFor="phone">
            Phone:
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="tel"
            name="phone"
            value={phone}
            placeholder="Enter your phone no"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-4 text-sm" htmlFor="email">
            Email:
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="email"
            name="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleChange}
            required
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="mr-4 text-sm" htmlFor="password">
            Password:
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="password"
            name="password"
            value={password}
            placeholder="password"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-4 text-sm" htmlFor="passwordRepeat">
            Repeat password:{" "}
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="password"
            name="passwordRepeat"
            value={passwordRepeat}
            placeholder="repeat your password"
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-4 text-sm" htmlFor="company">
            Company (optional):
          </label>
          <input
            className="border-solid border rounded-md p-1"
            type="text"
            name="company"
            value={company}
            placeholder="Enter your company"
            onChange={handleChange}
          />
        </div>
        <ModalSubmitButton buttonText="Register" />

        <div className="flex justify-center">
          Already have an account? &nbsp;
          <button onClick={()=> {
              var event = new CustomEvent("login", {
                detail: "Please log in",
              });
              document.dispatchEvent(event);
            onClose()
            
            }}>
            <LoginPortal className="inline" />
          </button>
        </div>
      </form>
      <ToastContainer />
    </ModalContainer>
  );
};

export default RegisterModal;
