import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import ContactModal from "./listings/components/ContactModal";
const Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: 556px) {
    display: block;
  }
`;

const TopicList = styled.div`
  width: 300px;
  max-width: 100%;
  height: 600px;
  max-height: 90%;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 556px) {
    height: auto;
  }
`;

const Topic = styled.div`
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
  &:hover {
    background: #00ca8f;
    color: #fff;
  }
`;

const MessageContent = styled.div`
  width: 100%;
  margin: 10px;
  border-radius: 6px;
  border: solid 1px #cccccc;
`;

const NoMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f172a;
  font-weight: 700;
`;

const MessageBox = styled.div`
  width: 100%;
  color: #0f172a;
  font-size: 12px;
  padding: 20px;
  position: relative;
  padding-bottom: 20px;
`;

const MessageWrapper = styled.div`
  height: 600px;
  padding: 20px;
  max-height: 80%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Message = styled.div`
  background: ${(props) => (props.primary ? "#e5e5ea" : "#248bf5")};
  color: ${(props) => (props.primary ? "#000" : "#fff")};
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  margin-bottom: 5px;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
  align-self: ${(props) => (props.primary ? "flex-start" : "flex-end")};
  &:before {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;

    border-bottom-left-radius: ${(props) =>
      props.primary ? "" : "0.8rem 0.7rem"};
    border-bottom-right-radius: ${(props) =>
      props.primary ? "0.8rem 0.7rem" : ""};

    border-right: ${(props) => (props.primary ? "" : "1rem solid #248bf5")};
    border-left: ${(props) => (props.primary ? "1rem solid #e5e5ea;" : "")};
    right: ${(props) => (props.primary ? "" : "-0.35rem")};
    left: ${(props) => (props.primary ? "-0.35rem" : "")};
    transform: translate(0, -0.1rem);
  }
  &:after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;

    background-color: #fff;
    border-bottom-left-radius: ${(props) => (props.primary ? "" : "0.5rem")};
    border-bottom-right-radius: ${(props) => (props.primary ? "0.5rem" : "")};

    right: ${(props) => (props.primary ? "" : "-40px")};
    left: ${(props) => (props.primary ? "20px" : "")};

    transform: translate(-30px, -2px);
    width: 10px;
  }
`;

const Messages = () => {
  const [topics, setTopics] = useState([]);
  const [open, setOpen] = useState(false);
  const [listing, setListing] = useState();
  const [user, setUser] = useState();

  const [content, setContent] = useState();
  const [activeMessage, setActiveMessage] = useState();

  const getListing = (listingId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/${listingId}`)
      .then((res) => {
        setListing(res.data);
      })
      .catch((err) => {
        toast.error("Failed to get offer, it may be already deleted", {
          position: "bottom-left",
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const userString = localStorage.getItem("user");
      setUser(JSON.parse(userString));
    }
  }, []);

  const getUnreadCount = (message) => {
    let unreadCount = 0;
    if (localStorage.getItem("user")) {
      const userString = localStorage.getItem("user");
      for (const msg of message.messages) {
        if (msg.read === false) {
          if (
            JSON.stringify(msg._sender) !==
            JSON.stringify(JSON.parse(userString)._id)
          ) {
            unreadCount++;
          }
        }
      }
      message["unread"] = unreadCount;
    }
    return message;
  };

  const getConversations = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/messages`, {
        withCredentials: true,
      })
      .then((response) => {
        const msgTopics = [];
        for (const message of response.data.messages) {
          const topic = getUnreadCount(message);
          msgTopics.push(topic);
        }
        setTopics(msgTopics);
        if (activeMessage) {
          const prevActive = response.data.messages.findIndex(
            (el) => el._id === activeMessage._id
          );
          setActiveMessage(response.data.messages[prevActive]);
        }
      })
      .catch((err) => {
        toast.error("Failed to get messages", {
          position: "bottom-left",
        });
      });
  };
  useEffect(() => {
    getConversations();
  }, []);

  const markAsRead = (message) => {
    if (localStorage.getItem("user")) {
      const userString = JSON.parse(localStorage.getItem("user"));
      const ind = message.messages.findIndex((el) => el.read === false);
      if (
        ind > -1 &&
        JSON.stringify(message.messages[ind]._sender) !==
          JSON.stringify(userString._id)
      ) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/mark-as-read`, {
            _id: message._id,
          })
          .then((res) => {
            getConversations();
          });
      }
    }
    return message;
  };

  const setActiveConvo = (el) => {
    getListing(el._listing);
    setActiveMessage(el);
    markAsRead(el);
  };

  const handleSubmit = () => {
    let user;
    if (localStorage.getItem("user")) {
      const userString = localStorage.getItem("user");
      user = JSON.parse(userString);
    }
    axios
      .put(`${process.env.REACT_APP_API_URL}/messages`, {
        _id: activeMessage?._id,
        _sender: user?._id,
        content: content,
      })
      .then(() => {
        toast.success("Message Sent", {
          position: "bottom-left",
        });
        setOpen(false);
        setContent('')
        getConversations();
      });
  };

  return (
    <>
      <ToastContainer />
      <Wrapper>
        <TopicList>
          {topics.map((el) => {
            return (
              <Topic
                key={el._id}
                onClick={() => {
                  setActiveConvo(el);
                }}
              >
                {el.title}{" "}
                {el.unread > 0 && (
                  <span className="msg-counter--topic">
                    <span className="msg-counter--inner">{el.unread}</span>
                  </span>
                )}
              </Topic>
            );
          })}
        </TopicList>
        <MessageContent>
          {activeMessage ? (
            <>
              <MessageBox>
                <MessageWrapper>
                  <Message primary>
                    {listing?._creator === user._id ? (
                      <>
                        {`You have received interest for the following listing `}
                        <a
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          target="_blank#"
                          href={`/listing/${listing?._id}`}
                        >
                          {listing?.brand} {listing?.model}
                        </a>
                        <p>{`Here are the contacts for the person who sent the offer: `}</p>
                        {activeMessage?.sender_name && (
                          <p>{`Name:  ${activeMessage.sender_name}`}</p>
                        )}
                        {activeMessage?.sender_company && (
                          <p>{`Company:  ${activeMessage.sender_company}`}</p>
                        )}
                        {activeMessage?.sender_email && (
                          <p>{`Email:  ${activeMessage.sender_email}`}</p>
                        )}
                        {activeMessage?.sender_phone && (
                          <p>{`Phone:  ${activeMessage.sender_phone}`}</p>
                        )}
                      </>
                    ) : (
                      <>
                        {`You have shown interest for the following listing `}
                        <a
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          target="_blank#"
                          href={`/listing/${listing?._id}`}
                        >
                          {listing?.brand} {listing?.model}
                        </a>
                      </>
                    )}
                  </Message>
                  {activeMessage.messages.map((el) => {
                    if (user) {
                      if (user._id === el._sender) {
                        return <Message>{el.content}</Message>;
                      } else {
                        return <Message primary>{el.content}</Message>;
                      }
                    }
                  })}
                </MessageWrapper>
                <div className="msg-resp">
                  <textarea
                    className="msg-text-response"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  ></textarea>
                  <button
                    className="msg-sub"
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                  >
                    Send response
                  </button>
                </div>
              </MessageBox>
            </>
          ) : (
            <>
              {topics?.length > 0 ? (
                <NoMessage>Select message from left</NoMessage>
              ) : (
                <NoMessage>No messages to display</NoMessage>
              )}
            </>
          )}
        </MessageContent>
      </Wrapper>
    </>
  );
};

export default Messages;
