import Modal from "styled-react-modal";
import styled from "styled-components";
import Input from "../../../components/Form/Input";
import { useEffect, useState } from "react";
import Textarea from "../../../components/Form/Textarea";
import { ToastContainer, toast } from "react-toastify";

import styles from "../../../pages/listings/styles/styles.module.css";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
const StyledModal = Modal.styled`
width: 800px;
max-width: 100%; 
height: 650px;
max-heigth: 90%;
flex-wrap: wrap;
background-color: white;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    display: block;
    padding-top: 15px;
    text-align: center;
  }
`;
const ContactModal = ({
  open,
  setOpen,
  listing,
  messageId,
  getConversations,
}) => {
  const [message, setMessage] = useState({
    title: "",
    content: "",
    sender_name: "",
    sender_email: "",
    sender_phone: "",
  });
  const [errors, setErrors] = useState();
  useEffect(() => {
    if (listing?.brand) {
      setMessage({
        ...message,
        title: `Query for: ${listing.brand} ${listing?.model}`,
      });
    }
  }, [listing]);

  const handleChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };
  console.log(message);

  const handleSubmit = () => {
    setErrors(false);
    if (!messageId && message.title.length < 5) {
      setErrors({ ...errors, title: "Minimum 5 characters" });
      return;
    }
    if (message.content.length < 5) {
      setErrors({ ...errors, content: "Minimum 5 characters" });
      return;
    }
    if (!messageId && message.sender_email.length < 5) {
      setErrors({ ...errors, content: "Email is required" });
      return;
    }

    let user;
    if (localStorage.getItem("user")) {
      const userString = localStorage.getItem("user");
      user = JSON.parse(userString);
    }

    if (!messageId) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/messages`, {
          _receiver: listing._creator,
          _listing: listing._id,
          _sender: user?._id,
          title: message.title,
          author_email: listing?.author_email,
          author_name: listing?.author_name,
          author_phone: listing?.author_phone,
          author_company: listing?.author_company,
          sender_name: message?.sender_name,
          sender_email: message?.sender_email,
          sender_phone: message?.sender_phone,
          messages: {
            _sender: user?._id,
            content: message.content,
          },
        })
        .then((result) => {
          toast.success("Message Sent", {
            position: "bottom-left",
          });
          setOpen(false);
          setMessage({
            title: "",
            content: "",
            title: "",
            content: "",
            sender_name: "",
            sender_email: "",
            sender_phone: "",
          });
        })
        .catch((error) => {
          toast.error("Failed to send message, please try again", {
            position: "bottom-left",
          });
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}/messages`, {
          _id: messageId,
          _sender: user?._id,
          content: message.content,
        })
        .then((result) => {
          toast.success("Message Sent", {
            position: "bottom-left",
          });
          setOpen(false);
          setMessage({
            title: "",
            content: "",
            title: "",
            content: "",
            sender_name: "",
            sender_email: "",
            sender_phone: "",
          });
          getConversations();
        })
        .catch((error) => {
          toast.error("Failed to send message, please try again", {
            position: "bottom-left",
          });
        });
    }
  };

  return (
    <>
      <StyledModal
        isOpen={open}
        onBackgroundClick={() => setOpen(!open)}
        onEscapeKeydown={() => setOpen(!open)}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <button
            style={{
              position: "absolute",
              top: "10px",
              fontWeight: "bold",
              zIndex: 22,
              right: "15px",
            }}
            onClick={() => setOpen(false)}
          >
            X
          </button>
          <Wrapper>
            {!messageId && (
              <>
                <Input
                  width={"md:w-1/2"}
                  handleChange={handleChange}
                  type="text"
                  name="title"
                  value={message?.title}
                  label="Title"
                  errorMessage={errors?.title}
                />

                <Input
                  width={"md:w-1/2"}
                  handleChange={handleChange}
                  type="text"
                  name="sender_name"
                  value={message?.sender_name}
                  label="Your name"
                  errorMessage={errors?.sender_name}
                />
                <Input
                  width={"md:w-1/2"}
                  handleChange={handleChange}
                  type="text"
                  required={true}
                  name="sender_email"
                  value={message?.sender_email}
                  label="Your email"
                  errorMessage={errors?.sender_email}
                />

                <div className="phone-input inp-wrap w-full px-3 mb-6 md:mb-5 md:w-1/2">
                  <PhoneInput
                    value={message?.sender_phone}
                    defaultCountry="de"
                    onChange={(e) =>
                      setMessage({
                        ...message,
                        ["sender_phone"]: e,
                      })
                    }
                  />
                </div>
              </>
            )}
            <Textarea
              handleChange={handleChange}
              type="textarea"
              minHeight={"200px"}
              name="content"
              value={message?.content}
              label="Content"
              errorMessage={errors?.content}
            />
            <button
              style={{ marginBottom: "20px" }}
              className={styles.submit}
              onClick={(e) => handleSubmit(e)}
              type="submit"
            >
              Send
            </button>
          </Wrapper>
        </div>
      </StyledModal>
      <ToastContainer />
    </>
  );
};

export default ContactModal;
