import axios from "axios";
import { useSearchParams } from "react-router-dom";
const PlanModal = ({ isContent }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const createCheckout = (price_id, days) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        price_id: price_id,
        id: id,
        days: days,
      })
      .then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div
        id="staticModal"
        aria-hidden="true"
        className="relative fixed-center z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {isContent ? "Pricing Plans" : "Select Pricing"}
              </h3>
            </div>
            <div className="flex p-2 flex-wrap justify-center	">
              <div className="p-2 space-y-2 min-w-320">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  <div className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      14 days
                    </h5>
                    <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                      The advertisement will be visible for 14 days after the
                      payment
                    </p>
                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      2.99€
                    </h5>
                    {!isContent && (
                      <button
                        onClick={() =>
                          createCheckout("price_1O2BwnK7sQepzutZx1wF8jzy", 14)
                        }
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Select
                        <svg
                          className="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </p>
              </div>
              <div className="p-2 space-y-2">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  <div className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      30 days
                    </h5>
                    <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                      The advertisement will be visible for 30 days after the
                      payment
                    </p>
                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      4.99€
                    </h5>
                    {!isContent && (
                      <button
                        onClick={() =>
                          createCheckout("price_1O2BxGK7sQepzutZtcNWnFtD", 30)
                        }
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Select
                        <svg
                          className="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </p>
              </div>
              <div className="p-2 space-y-2">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  <div className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      60 days
                    </h5>
                    <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                      The advertisement will be visible for 60 days after the
                      payment
                    </p>
                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      7.99€
                    </h5>
                    {!isContent && (
                      <button
                        onClick={() =>
                          createCheckout("price_1O2BxbK7sQepzutZZ5rhDeW6", 60)
                        }
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Select
                        <svg
                          className="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanModal;
