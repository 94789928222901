import { useEffect } from "react";
import PlanModal from "../components/Modals/PlanModal";

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <PlanModal />;
};

export default Payment;
