import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/reducers/userReducer";
import ModalContainer from "../../General/ModalContainer";
import CloseButton from "../../../assets/icons/x-mark.js";
import ModalSubmitButton from "../../General/Buttons/ModalSubmitButton";

const LoginModal = ({ onClose }) => {
  const navigate = useNavigate();
  const [isReset, setIsReset] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [, setCookie] = useCookies(["token"]);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const { email, password } = inputValue;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) => {
    window.location.reload();
    toast.success(msg, {
      position: "bottom-left",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { headers, data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,

        {
          ...inputValue,
        },
        { withCredentials: true }
      );

      const { success, message, user } = data;

      if (success) {
        const cookies = headers.get("Set-Cookie");

        // Save the cookies in your preferred way
        // For example, you can use document.cookie to save them
        // This is a basic example, you may need to parse and handle the cookies differently
        document.cookie = cookies;
        handleSuccess(message);

        if (user) {
          setCookie("token", "transconnectorUser");
          localStorage.setItem("user", JSON.stringify(user));

          dispatch(setUser(user));
        }
        setTimeout(() => {
          navigate("/");
          onClose();
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }

    setInputValue({
      ...inputValue,
      email: "",
      password: "",
    });
  };

  const finishReset = () => {
    setIsSubmitted(false);
    setIsReset(false);
    onClose();
  };
  const resetPassword = () => {
    setIsSubmitted(true);

    axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
      email: inputValue.email,
    });
  };

  return (
    <ModalContainer onClose={onClose} className="form_container ">
      {!isSubmitted && <CloseButton onClose={onClose} />}
      {!isReset ? (
        <>
          <h2 className="text-2xl font-bold">Log In</h2>
          <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-1">
              <label className="mr-4" htmlFor="email">
                Email:
              </label>
              <input
                className="border-solid border rounded-md p-2"
                type="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="mr-4" htmlFor="password">
                Password:
              </label>
              <input
                className="border-solid border rounded-md p-2"
                type="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                onChange={handleChange}
              />
            </div>
            <ModalSubmitButton buttonText="Log In" />
          </form>
        </>
      ) : (
        <>
          <h2 className="text-2xl font-bold">Recover Password</h2>
          {!isSubmitted ? (
            <>
              <div className="flex flex-col gap-1">
                <label className="mr-4" htmlFor="email">
                  Email:
                </label>
                <input
                  className="border-solid border rounded-md p-2"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={handleChange}
                />
              </div>
              <div onClick={() => resetPassword()}>
                <ModalSubmitButton buttonText="Recover" />
              </div>
            </>
          ) : (
            <>
              <p>
                An email has been sent with instructions to recover your
                password
              </p>
              <div onClick={() => finishReset()}>
                <ModalSubmitButton buttonText="Close" />
              </div>
            </>
          )}
        </>
      )}
      <div className="flex flex-col text-center gap-6">
        {!isReset && (
          <>
            {" "}
            <div style={{cursor: "pointer"}} onClick={() => setIsReset(true)}>Reset password</div>
            <div
              onClick={() => {
                var event = new CustomEvent("register", {
                  detail: "Please log in",
                });
                document.dispatchEvent(event);
                onClose();
              }}
            >
              No account? Create one
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </ModalContainer>
  );
};

export default LoginModal;
