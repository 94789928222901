import styles from "./styles.module.css";

import LogoWhite from "../../assets/log_w.svg";
const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className="container lg mx-auto">
        <div className="grid grid-cols-4 gap-4">
          <div>
            <img src={LogoWhite} />
          </div>
          <div></div>
          <div>
            <h2>PRODUCT</h2>
            <p>
              <a href="/about">About</a>
            </p>
            <p>
              <a href="/pricing">Pricing</a>
            </p>
            <p>
              <a href="/faq">FAQ</a>
            </p>
          </div>
          <div>
            <h2>OTHER</h2>
            <p>
              <a href="/terms-and-conditions">Terms & Conditions</a>
            </p>
            <p>
              <a href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div></div>
          <div>
            <div className={styles.sup}>
              <h2>SUPPORT</h2>
              <p>Our customer service team is 24/7 support@truck7.eu</p>
            </div>
            <div className={styles.sup2}>
              <h2>CONTACT</h2>
              <p><a href="mailto:support@truck7.eu">info@truck7.eu</a></p>
              <p><a href="tel:+37255514514">+372 55 514 514</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
