import React, { useState } from "react";
import { createPortal } from "react-dom";
import RecoverModal from "./Modals/recoverModal";

const RecoverPortal = () => {
  const [showModal, setShowModal] = useState(false);

  //LISTEN TO EVETN
  document.addEventListener("resetPassword", function (e) {
    setShowModal(true);
  });
  return (
    <div>
      {showModal &&
        createPortal(
          <RecoverModal
            onClose={() => {
              setShowModal(false);
            }}
          />,
          document.body
        )}
    </div>
  );
};

export default RecoverPortal;
