import FilterButton from "../../assets/icons/Union.svg";

const Search = (props) => {
  return (
    <>
    {/*
    <div className=" flex justify-center flex-none">
      <div className="flex flex-1 justify-center">
        <input
          className="border-solid border rounded-md p-2 search-input"
          type="text"
          name="search"
        ></input>
        <img className="ml-5" src={FilterButton} />
      </div>
    </div>
  */}
    </>
  );
};

export default Search;
