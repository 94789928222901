
import OffersContainer from "../components/Offers/OffersContainer"
import { useNavigate } from "react-router-dom"

const MyOffers = () => {

    const navigate = useNavigate()
    const creator = localStorage.getItem('user')
    if(!creator) {
        navigate('/')
    }
    return(
        <OffersContainer limit={24} user={JSON.parse(creator)._id} />
    )
}

export default MyOffers