import FilterElement from "./FilterElement";
import CategoryTab from "./CategoryTab";

import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import countries from "../../data/countries.json";
import brands from "../../data/brands.json";
import listing_config from "../../config/create_listing";
import FormSection from "../../pages/listings/components/FormSection";

function upsert(array, element) {
  if (element && element.name) {
    const i = array.findIndex((_element) => _element.name === element.name);
    if (i > -1) {
      array[i] = element;
      if (!element.value) {
        array.splice(i, 1);
      }
    } else array.push(element);
  }
  return array;
}

const Filter = (props) => {
  const navigate = useNavigate();

  const [filterOptions, setFilterOptions] = useState([]);
  const [userFilter, setUserFilter] = useState([]);
  const [advacedVisible, setAdvancedVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("tractor-unit");
  const [urlParams, setUrlParams] = useState();

  const getUserSelectedFilter = (data) => {
    const result = upsert(userFilter, data);
    setUserFilter(result);
  };

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);

    const paramsObject = Object.fromEntries(params.entries());
    setUrlParams(paramsObject);

    const category = params.get("category");
    if (category !== null) {
      const result = upsert(userFilter, { name: "category", value: category });
      setUserFilter(result);
      setSelectedCategory(category ? category : "tractor-unit");
    }
  }, []);

  const handleChange = (name) => {
    getUserSelectedFilter({ name: name.target.name, value: name.target.value });
  };

  const submitFilter = () => {
    let queryString = "";
    if (userFilter.length > 0) {
      for (const filter of userFilter) {
        if (queryString.length === 0) {
          queryString = `?${filter.name}=${filter.value}`;
        } else {
          queryString = queryString + `&${filter.name}=${filter.value}`;
        }
      }
      queryString = queryString.replace(' ', '')
      navigate(`/offers${queryString}`);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/filters`)
      .then((res) => {
        setFilterOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div
        className="flex justify-start content-center filter-wrapper"
        style={{ alignItems: "center" }}
      >
        <FilterElement
          label="Country"
          name="country"
          values={countries}
          setFilter={getUserSelectedFilter}
          params={userFilter}
        ></FilterElement>
        <FilterElement
          label="Brand"
          name="brand"
          values={brands}
          params={userFilter}
          setFilter={getUserSelectedFilter}
        ></FilterElement>
        <FilterElement
          label="Vehicle Type"
          name="category"
          values={filterOptions.vehicleType}
          setFilter={getUserSelectedFilter}
          params={userFilter}
        ></FilterElement>
        <FilterElement
          label="First Registration"
          name="first_registration"
          values={filterOptions.firstRegistration}
          setFilter={getUserSelectedFilter}
          params={userFilter}
        ></FilterElement>
        <button
          style={{ marginLeft: "auto", maxHeight: "38px" }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            submitFilter();
            //document
            //  .getElementById("offers")
            //  .scrollIntoView({ behavior: "smooth", block: "center" });
          }}
        >
          Search{" "}
        </button>
      </div>
      <div className={styles.tabWrapper}>
        <p>Explore by Category: </p>
        {filterOptions.tabFilters &&
          filterOptions.tabFilters.map((cat) => {
            return (
              <CategoryTab
                key={cat.name}
                value={cat.name}
                name={cat.name}
                count={cat.count}
              />
            );
          })}
      </div>

      <>
        <div
          className={styles.advancedButton}
          onClick={() => {
            setAdvancedVisible(!advacedVisible);
          }}
        >
          Advanced Filters
        </div>
        <div className={`${styles.advancedBox} ${styles[advacedVisible]}`}>
          <div className="flex flex-wrap mx-3 mb-6 advancedBoxInner">
            <FormSection
              params={urlParams}
              fields={listing_config.filter((el) => {
                if (el.filterEnabled && el.filterEnabled.length > 0) {
                  if (el.filterEnabled.includes(selectedCategory)) {
                    return true;
                  }
                }
              })}
              isFilter={true}
              handleChange={handleChange}
              listingValue={userFilter}
            ></FormSection>
          </div>
          <div style={{ textAlign: "right" }}>
            <button
              style={{ marginLeft: "auto", maxHeight: "38px" }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                submitFilter();

                // document
                //   .getElementById("offers")
                //  .scrollIntoView({ behavior: "smooth", block: "center" });
              }}
            >
              Search{" "}
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default Filter;
