import Filter from "../components/Filter";
import Search from "../components/Filter/Search";
import OffersContainer from "../components/Offers/OffersContainer";

const Offers = () => {
  return (
    <>
      <Search></Search>
      <Filter></Filter>
      <OffersContainer limit={24} visible={true} />
    </>
  );
};

export default Offers;
