import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { setUser } from "../store/reducers/userReducer";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import GeneralContainer from "../components/General/GeneralContainer";
import Filter from "../components/Filter";
import Search from "../components/Filter/Search";
import PromotedContainer from "../components/Offers/PromotedContainer";
import OffersContainer from "../components/Offers/OffersContainer";

import Banner from "../assets/banner.png";
import { useSearchParams } from "react-router-dom";
const Home = (props) => {
  const { USER } = props;
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window.location.href.indexOf("recover") != -1) {
      const hash = searchParams.get("h");
      if (hash) {
        var event = new CustomEvent("resetPassword", {
          detail: "Please log in",
        });
        document.dispatchEvent(event);
      }
    }
  }, []);

  useEffect(() => {
    if (!cookies.token) {
      return;
    }
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      dispatch(setUser(JSON.parse(userInfo)));
    }
  }, [cookies, removeCookie, dispatch]);

  return (
    <GeneralContainer>
      <div className="bg-blue"></div>
      <Search></Search>
      <Filter></Filter>
      <PromotedContainer />
      <OffersContainer
        limit={16}
        visible={false}
        newOffers={true}
        sectionTitle="New Offers"
      />
      <ToastContainer />
      <a href="https://transconnector.eu">
        <img src={Banner} />
      </a>
    </GeneralContainer>
  );
};

export default Home;
