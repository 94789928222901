
import PlanModal  from "../../components/Modals/PlanModal";

const Pricing = () => {
  return (
    <>
      <h1 className="content-page-title">Pricing</h1><PlanModal isContent/>
    </>
  );
};

export default Pricing;
