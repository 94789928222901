import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ModalContainer from "../../General/ModalContainer";
import CloseButton from "../../../assets/icons/x-mark.js";
import ModalSubmitButton from "../../General/Buttons/ModalSubmitButton";
import { useSearchParams } from "react-router-dom";

const RecoverModal = ({ onClose }) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState({
    password: "",
  });

  const { password } = inputValue;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    const hash = searchParams.get("h");
    axios
      .post(`${process.env.REACT_APP_API_URL}/is-recoverable`, { hash })
      .then((res) => {
        if (!res.data.recoverable) {
          navigate("/");
        }
      });
  }, []);

  const handleSuccess = () => {
    toast.success("New password saved", {
      position: "bottom-left",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hash = searchParams.get("h");

    await axios.post(`${process.env.REACT_APP_API_URL}/recover`, {
      hash: hash,
      password: inputValue.password,
    });
    handleSuccess()

    setInputValue({
      ...inputValue,
      password: "",
    });

    setTimeout(() => {
      navigate("/");
      onClose();
    }, 1000);
  };

  return (
    <ModalContainer onClose={onClose} className="form_container ">
      {!isSubmitted && <CloseButton onClose={onClose} />}
      {
        <>
          <h2 className="text-2xl font-bold">Reset password</h2>
          <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-1">
              <label className="mr-4" htmlFor="password">
                New Password:
              </label>
              <input
                className="border-solid border rounded-md p-2"
                type="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                onChange={handleChange}
              />
            </div>
            <ModalSubmitButton buttonText="Reset Password" />
          </form>
        </>
      }

      <ToastContainer />
    </ModalContainer>
  );
};

export default RecoverModal;
