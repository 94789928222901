import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import LoginModal from "./Modals/loginModal";

const LoginPortal = ( ) => {
  const [showModal, setShowModal] = useState(false);


  //LISTEN TO EVETN
  document.addEventListener("login", function (e) {
    setShowModal(true);
  });

  return (
    <div>
      <button
        onClick={() => {
          setShowModal(true);
        }}
      >
        Log in
      </button>
      {showModal &&
        createPortal(
          <LoginModal
            onClose={() => {
              setShowModal(false);
            }}
          />,
          document.body
        )}
    </div>
  );
};

export default LoginPortal;
