import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AccountMenu from "./accountMenu";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import LoginPortal from "../Auth/LoginPortal";
import RegisterPortal from "../Auth/RegisterPortal";
import RecoverPortal from "../Auth/RecoverPortal";

import logo from "../../assets/logo.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";

const NavBar = (props) => {
  const [, removeCookie] = useCookies([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [unreadCount, setUnreadcount] = useState(0);
  const Logout = () => {
    removeCookie("token");
    localStorage.removeItem("user");
    removeCookie("AuthToken");

    navigate("/");
    setUser();
  };
  const location = useLocation();
  useLayoutEffect(() => {
    document.getElementById("menu-toggle").checked = false;
  }, [location]);

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.stringify(userString);
      setUser(user);
      axios
        .get(`${process.env.REACT_APP_API_URL}/get-unread`, {
          withCredentials: true,
        })
        .then((res) => {
          setUnreadcount(res.data.unreadCount);
        });
    }
  }, []);

  return (
    <header>
      <nav className="navBar flex align-middle justify-left gap-3">
        <NavLink className="home-link" to="/">
          <img src={logo} />
        </NavLink>
        <input id="menu-toggle" type="checkbox" />
        <label class="menu-button-container" for="menu-toggle">
          <div className="menu-button"></div>
        </label>
        <div
          className="navInner flex align-middle justify-left gap-3"
          style={{ width: "100%" }}
        >
          <NavLink to="/offers">Offers</NavLink>

          <NavLink to="/add-listing">Sell Your Truck</NavLink>
          <div className="login-links">
            {!user ? (
              <LoginPortal>Login</LoginPortal>
            ) : (
              <>
                <NavLink to="/profile">My Profile</NavLink>

                <NavLink to="/my-offers">My Adverts</NavLink>

                <NavLink to="/messages" className="rel">
                  Messages
                  {unreadCount > 0 && (
                    <span className="msg-counter">
                      <span className="msg-counter--inner">{unreadCount}</span>
                    </span>
                  )}
                </NavLink>

                <button onClick={Logout}>Log out</button>
              </>
            )}
            {!user ? <RegisterPortal>Join now</RegisterPortal> : ""}
            <RecoverPortal />
            <AccountMenu></AccountMenu>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
